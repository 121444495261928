<template>
  <div class="rrCard border">
    <div class="row">
      <div class="left">
        <p>
          Matricula: <span> {{ obj.licensePlate }} </span>
        </p>
        <p class="blue">Estimativa</p>
        <p class="orange">
          Avaliação expira dia
          {{ convertDateToStr(obj.lastValuationDate, true) }}
        </p>
        <p>
          Marca: <span> {{ obj.brand }} </span>
        </p>
        <p>
          Modelo: <span> {{ obj.model }} </span>
        </p>
      </div>
      <div>
        <p><span>Valor Automóvel</span></p>
        <p class="price">
          {{ formatPriceNumber(obj.actualValuation, 2, "€") }}
        </p>
      </div>
    </div>
    <div class="row">
      <p>
        Data da Matricula: <span> {{ obj.month }} / {{ obj.year }} </span>
      </p>
      <p>
        KM: <span> {{ formatPriceNumber(obj.kilometers, 0) }} </span>
      </p>
    </div>
  </div>
</template>
<script>
import { formatPriceNumber, convertDateToStr } from "@/functions/general.js";
import { mapGetters } from "vuex";
export default {
  name: "RetakeCard",
  props: {
    obj: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    ...mapGetters({
      userName: "user/getUserName",
    }),
  },
  methods: {
    formatPriceNumber,
    convertDateToStr,
  },
};
</script>
<style lang="scss" scoped>
@import "./RRCard.scss";
</style>
