<template>
  <div>
    <p class="childTitle">{{ getComponentTitle }}</p>

    <template v-if="$route.name == 'MyRetake'">
      <RetakeCard v-for="item in getLst" :key="item.id" :obj="item" />
    </template>

    <template v-if="$route.name == 'Reservation'">
      <ReservationCard v-for="item in getLst" :key="item.id" :obj="item" />
    </template>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import RetakeCard from "@/components/Header/Children/RetakeCard.vue";
import ReservationCard from "@/components/Header/Children/ReservationCard.vue";

export default {
  name: "RetakeOrReservation",
  components: {
    RetakeCard,
    ReservationCard,
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    $route: function () {
      this.fetchData();
    },
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters({
      getReservationLst: "user/mapReservationLst",
      getRetakesLst: "user/mapRetakesLst",
    }),
    getComponentTitle() {
      const routeName = this.$route.name;
      return routeName == "Reservation" ? "Minhas Reservas" : "Minhas Retomas";
    },
    getLst() {
      let arr = [];
      const routeName = this.$route.name;
      if (routeName == "MyRetake") arr = this.getRetakesLst;
      if (routeName == "Reservation") arr = this.getReservationLst;

      return arr;
    },
  },
  methods: {
    fetchData() {
      const routeName = this.$route.name;
      routeName == "MyRetake" && this.$store.dispatch("user/userRRlst");

      routeName == "Reservation" &&
        this.$store.dispatch("user/userRRlst", false);
    },
  },
};
</script>
s
<style lang="scss" scoped>
@import "./GeralChildStyle.scss";
</style>
