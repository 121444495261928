<template>
  <div :class="{ rrCard: true, border: hasBorder }">
    <div class="row center">
      <div class="imgCont">
        <img :src="obj.vehicle.imageUrl" alt="Imagem do veiculo" />
      </div>
      <div>
        <p>
          <span>{{ obj.vehicle.model }} </span>
        </p>
        <p>{{ obj.vehicle.description }}</p>
      </div>

      <div class="fullSM">
        <p class="price">
          {{ formatPriceNumber(obj.finance.pricePvp, 2, "€") }}
        </p>
      </div>
    </div>
    <div v-show="hasBorder" class="row">
      <p v-if="status == -1" class="grey">Expirado</p>
      <div class="side-side" v-else-if="status == 1">
        <p class="green">Pagamento concluído</p>
        <span>Brevemente, há-de ser contactado por um concessionário</span>
      </div>

      <p v-else-if="status == 0" class="blue">Pagamento em espera</p>

      <ButtonSubmit
        btnClass="secondary-btn "
        btnLabel="Ver detalhe"
        @submit="
          $router.push({
            path: '/user/' + userName + '/reservas/' + obj.model,
            query: { id: obj.id },
          })
        "
      />
    </div>
  </div>
</template>
<script>
import ButtonSubmit from "@/components/Buttons/ButtonSubmit.vue";
import { formatPriceNumber, convertDateToStr } from "@/functions/general.js";
import { mapGetters } from "vuex";
export default {
  name: "ReservationCard",
  props: {
    obj: {
      type: Object,
      default: () => {
        return {};
      },
    },
    hasBorder: { type: Boolean, default: true },
  },
  data() {
    return {};
  },
  components: {
    ButtonSubmit,
  },
  computed: {
    ...mapGetters({
      userName: "user/getUserName",
    }),
    status() {
      /* STATUS VALUES
       * 1 == PAGAMENTO CONCLUIDO
       * 0 == PAGAMENTO EM ESPERA
       * -1 == PAGAMENTO CANCELADO
       */
      let currentDate = new Date();
      if (this.obj.reserveType == 0) {
        if (this.obj.paymentStatus == 8) {
          return 0;
        } else if (this.obj.paymentStatus == 7) {
          return 1;
        } else if (currentDate > this.obj.paymentExpiration) {
          return -1;
        } else {
          return 1;
        }
      } else {
        return 1;
      }
    },
  },
  methods: {
    formatPriceNumber,
    convertDateToStr,
  },
};
</script>
<style lang="scss" scoped>
@import "./RRCard.scss";
</style>
